import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { xPad } from '../utils/theme'

const ArticleHeader = styled.header`
  position: relative;
  background-color: #141414;
  height: ${props => props.height || '600px'};

  .gatsby-image-outer-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

const HeaderTitle = styled.h3`
  color: #fff;
  position: relative;
  z-index: 1;
  font-weight: 400;
  text-shadow: 0 1px 2px rgba(20, 20, 20, 0.15);
  font-size: 40px;

  @media (min-width: 600px) {
    padding-right: 1.5rem;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: 1220px;
  max-width: 750px;
  z-index: 9;

  p {
    color: #fff;
    opacity: 0.8;
    line-height: 1.4;
  }

  ${xPad};

  padding-top: 4rem;
  padding-left: 6rem !important;

  @media (max-width: 600px) {
    padding-left: 2rem !important;
  }
`

interface IFigureProps {
  image: string
  darkness: string
}

const Figure = styled<IFigureProps, any>(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: ${props => props.darkness || '0.8'};

  & > img {
    object-fit: cover !important;
    object-fit: cover !important;
    object-position: center center !important;
  }
`
export default ({ darkness, title, image, children, ...rest }) => (
  <ArticleHeader {...rest}>
    <Container>
      <HeaderTitle>{title}</HeaderTitle>
      {children}
    </Container>
    <Figure darkness={darkness} fluid={image.fluid} />
  </ArticleHeader>
)
